import React, {useEffect} from 'react'
import {graphql} from 'gatsby'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import BlogBlockSection from '../sections/BlogBlockSection'
import ContentContainer from '../components/ContentContainer'
import BlogContent from '../components/BlogContent'
import BlogHero from '../components/BlogHero'
import BlogNav from '../components/BlogNav'
import ShowMob from '../components/ShowMob'
import FormSubscribe from '../components/FormSubscribe'
import GeneralContacts from '../components/GeneralContacts'
import Share from '../components/Share'
import SEO from '../components/SEO'
import Header from '../components/Header'
import Layout from '../components/Layout'

const ArticleTemplate = ({
  pageContext: {
    breadcrumb: {crumbs},
  },
  data,
  location,
  ...rest
}) => {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()

  useEffect(() => {
    if (typeof window !== 'undefined' && data.strapiBlog.en_disabled) {
      changeLanguage('de')
    }
  }, [])

  return (
    <Layout dataLocales={data} location={location}>
      <Header dataLang={data.strapiBlog.en_disabled} />
      <SEO
        title={data.strapiBlog.seo_title && data.strapiBlog.seo_title[language]}
        description={data.strapiBlog.seo_description && data.strapiBlog.seo_description[language]}
      />
      <BlogHero
        title={data.strapiBlog.title[language]}
        date={data.strapiBlog.date[language]}
        readingTime={data.strapiBlog.reading_time[language]}
        crumbs={crumbs}
      />
      <ContentContainer>
        <BlogNav navData={data.strapiBlog.navigation[language]} />
        <BlogContent content={data.strapiBlog.article[language]} />
      </ContentContainer>
      <BlogBlockSection
        data={data.allStrapiBlog.edges}
        title={t('sections.blog.title')}
        subtitle={t('sections.blog.subtitle')}
        location={location}
      />
      <ShowMob>
        <FormSubscribe />
        <Share />
      </ShowMob>
      <ShowMob>
        <GeneralContacts mb0 />
      </ShowMob>
    </Layout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query BlogTemplate($page_url: String!, $language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    strapiBlog(page_url: {eq: $page_url}) {
      en_disabled
      seo_title {
        de
        en
      }
      seo_description {
        de
        en
      }
      article {
        de
        en
      }
      date {
        de
        en
      }
      description_short {
        de
        en
      }
      navigation {
        en
        de
      }
      page_url
      reading_time {
        de
        en
      }
      title {
        de
        en
      }
    }
    allStrapiBlog(sort: {order: DESC, fields: date_for_sort}) {
      edges {
        node {
          article {
            de
            en
          }
          date {
            de
            en
          }
          description_short {
            de
            en
          }
          navigation {
            de
            en
          }
          reading_time {
            de
            en
          }
          page_url
          date_for_sort
          published_at
          title {
            de
            en
          }
        }
      }
    }
  }
`
